import { combineReducers } from "redux";
import { userReducer } from "./user/reducer";

// Model
import { User } from "../models/user.model";

// Interface
import { errorHandlerReducer, IErrorHandler } from "./errorHandler/reducer";
import { starterQuizListReducer } from "./starterQuizList/reducer";
import { StarterQuizList } from "../models/Activity.model";

export interface StoreState {
  user: User | null;
  errorHandler: IErrorHandler;
  starterQuizList: StarterQuizList;
}

export const reducers = combineReducers<StoreState>({
  user: userReducer,
  errorHandler: errorHandlerReducer,
  starterQuizList: starterQuizListReducer
});
