import React, { ReactNode } from "react";

/** Action */
export interface Action {
  type: "setIdActivitySelected";
  idActivitySelected: string;
}
/** init state */
const ActivityStateContext = React.createContext({ activitySelected: "" });
/** init dispatch */
const ActivityDispatchContext = React.createContext(
  (() => "") as React.Dispatch<Action>
);

/** Reducer */
function activityReducer(state: { activitySelected: string }, action: Action) {
  switch (action.type) {
    case "setIdActivitySelected": {
      return { activitySelected: action.idActivitySelected };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}${state}`);
    }
  }
}
/**
 * IProps
 */
interface IProps {
  children: ReactNode;
}
/**
 * Activity provider
 * @param children
 */
function ActivityProvider({ children }: IProps) {
  const [state, dispatch] = React.useReducer(activityReducer, {
    activitySelected: ""
  });
  return (
    <ActivityStateContext.Provider value={state}>
      <ActivityDispatchContext.Provider value={dispatch}>
        {children}
      </ActivityDispatchContext.Provider>
    </ActivityStateContext.Provider>
  );
}
/** use activity state */
function useActivityState() {
  const context = React.useContext(ActivityStateContext);
  if (context === undefined) {
    throw new Error("useActivityState must be used within a ActivityProvider");
  }
  return context;
}
/**  use activity dispatch */
function useActivityDispatch() {
  const context = React.useContext(ActivityDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useActivityDispatch must be used within a ActivityProvider"
    );
  }
  return context;
}

export { ActivityProvider, useActivityState, useActivityDispatch };
