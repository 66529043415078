import { action, ActionType } from "typesafe-actions";
import { StarterQuizList } from "../../models/Activity.model";

export const REMOVE_STARTER_QUIZ = "[STARTERQUIZLIST] REMOVE_STARTER_QUIZ ";
export const STORE_STARTER_QUIZ_LIST =
  "[STARTERQUIZLIST] STORE_STARTER_QUIZ_LIST";

export const removeStarterQuiz = (domainId: number) =>
  action(REMOVE_STARTER_QUIZ, domainId);
export const storeStarterQuizList = (starterQuizList: StarterQuizList) =>
  action(STORE_STARTER_QUIZ_LIST, starterQuizList);

const allActions = {
  removeStarterQuiz,
  storeStarterQuizList
};

export type starterQuizListActions = ActionType<typeof allActions>;
