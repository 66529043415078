// React
import React, { useState, useEffect, useRef } from "react";

// Styles
import { makeStyles, createStyles } from "@material-ui/core/styles";

// Libs
import moment from "moment";

// Material
import { Chip, Typography, Box } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      borderRadius: 20,
      width: 100,
      backgroundColor: "white"
    },
    btgroup: {
      height: 40,
      marginRight: 10
    },
    txtFinished: {
      color: "#7fbc4d"
    },
    icFinished: {
      color: "#7fbc4d"
    },
    icNotFinished: {
      color: "#eaeced",
      "&:hover": {
        color: "#eaeced"
      }
    }
  })
);

/** Timer props */
interface TimerProps {
  canPlayTimer: boolean;
  initialTime: number;
  activityTime: number;
  display: "chip" | "box";
}

/**
 * Timer component
 */
const Timer = ({
  canPlayTimer,
  initialTime,
  activityTime,
  display
}: TimerProps) => {
  const classes = useStyles();

  /** Time states */
  const [isFinished, setFinished] = useState<boolean>(
    initialTime >= activityTime
  );
  const [time, setTime] = useState<number>(
    initialTime >= activityTime ? activityTime : initialTime
  );

  const timerInterval = useRef<null | NodeJS.Timeout>(null);

  const currentTimeFormat = moment.utc(time * 1000).format("HH:mm:ss");

  const stop = () => {
    timerInterval && clearInterval(timerInterval.current!);
  };

  /**
   * startCounter
   */
  const start = () => {
    if (!isFinished) {
      timerInterval.current = setInterval(() => {
        setTime(time => {
          return time + 1;
        });
      }, 1000);
    }
  };

  /**
   * Use effect init start date when timer is started or finished
   */
  useEffect(() => {
    canPlayTimer ? start() : stop();
  }, [canPlayTimer]);

  /**
   * Use effect when leaving page where timer is displayed
   */
  useEffect(() => {
    return stop;
  }, []);

  /**
   * Use effect checked if timer is finished
   */
  useEffect(() => {
    if (time >= activityTime) {
      setFinished(true);
      stop();
    }
  }, [time]);

  const checkIcon = (
    <CheckCircleIcon
      className={isFinished ? classes.icFinished : classes.icNotFinished}
    />
  );

  return display === "chip" ? (
    <Chip
      label={currentTimeFormat}
      className={`${classes.btgroup} ${classes.chip} ${
        isFinished ? classes.txtFinished : ""
      }`}
      variant="outlined"
      onDelete={() => {
        return;
      }}
      deleteIcon={checkIcon}
    />
  ) : (
    <Box m={2} style={{ display: "flex" }}>
      <Typography
        variant="body1"
        gutterBottom={true}
        className={`${isFinished ? classes.txtFinished : ""}`}
      >
        {currentTimeFormat}
      </Typography>
      {checkIcon}
    </Box>
  );
};

export default React.memo(Timer);
