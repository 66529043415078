// React
import React, { useState, useEffect, useRef, memo, ChangeEvent } from "react";
import { Helmet } from "react-helmet";

// Lib
import { useSnackbar } from "notistack";

// Material
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  Grid
} from "@material-ui/core";

// Styles
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

// Providers
import { useScrollDispatch } from "../../shared/Scroll.provider";
import { ReportService, useContactPostMutation } from "../../graphql";

const CONTACT_TYPES = [
  {
    type: "Echange avec le référent pédagogique",
    service: ReportService.GrfPedagogique
  },
  {
    type: "Demande d'assistance produit",
    service: ReportService.GrfCommercial
  },
  { type: "Commandes et abonnements", service: ReportService.GrfCommercial },
  {
    type: "Codes d’accès (identifiants et mot de passe)",
    service: ReportService.GrfCommercial
  },
  { type: "Autres", service: ReportService.GrfCommercial }
];

const INITIAL_CONTACT = {
  type: CONTACT_TYPES[0].type,
  service: CONTACT_TYPES[0].service
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: "100%"
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(2)
    },
    title: {
      color: theme.palette.primary.main
    },
    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: "black"
      }
    },
    cssFocused: {},
    notchedOutline: {},
    button: {
      width: 170,
      height: 46,
      color: "white"
    },
    buttonarea: {
      display: "flex",
      justifyContent: "center"
    },
    snackbar: {
      top: 84,
      [theme.breakpoints.up("sm")]: {
        transform: "translateX(0%)"
      }
    }
  })
);

/**
 * Contact component
 */
const Contact = () => {
  /** Styles */
  const classes = useStyles();
  /** Graphql */
  const [postContact] = useContactPostMutation();
  /** Contact form */
  const [contact, setContact] = useState<{
    email?: string;
    message?: string;
    type: string;
    service: ReportService;
  }>({ ...INITIAL_CONTACT });
  const [errors, setErrors] = useState<{ email?: string; message?: string }>(
    {}
  );
  /** Contact sent */
  const [isContactSent, setContactSent] = useState<boolean>(false);
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();
  /** emailInput */
  const emailInputRef = useRef<HTMLDivElement>(null);
  /** messageInput */
  const messageInputRef = useRef<HTMLDivElement>(null);
  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Detect show change
   */
  useEffect(() => {
    if (isContactSent) {
      enqueueSnackbar("Votre message a bien été envoyé !", {
        variant: "success"
      });
    }
  }, [isContactSent]);

  /**
   * Manage scroll
   */
  useEffect(() => {
    dispatchScroll({ type: "setScrollPosition", scrollPosition: 0 });
  }, []);

  /**
   * Handle submit
   * @param event
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (contact.message && contact.email && validateEmail(contact.email)) {
      postContact({
        variables: {
          service: contact.service,
          email: contact.email,
          subType: contact.type,
          message: contact.message
        }
      }).then(() => {
        setContactSent(true);
        setContact({ ...INITIAL_CONTACT });
      });
    }
  };

  /**
   * on focus input
   * @param event
   */
  const handleFocus = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (event.target.id === "email" && emailInputRef.current) {
      dispatchScroll({
        type: "setScrollPosition",
        scrollPosition: emailInputRef.current.offsetTop - 20
      });
    } else if (event.target.id === "message" && messageInputRef.current) {
      dispatchScroll({
        type: "setScrollPosition",
        scrollPosition: messageInputRef.current.offsetTop - 20
      });
    }
  };

  /**
   * Validate mail
   * @param email
   */
  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email.toLowerCase());
  };

  /**
   * Change contact form values
   */
  const onChange = (field: string, value: string) => {
    setContactSent(false);
    if (field === "type") {
      const contactType = CONTACT_TYPES.find(c => c.type === value);
      if (contactType) {
        setContact({
          ...contact,
          type: contactType.type,
          service: contactType.service
        });
      }
    } else {
      setContact({ ...contact, [field]: value });
    }
  };

  /**
   * Change contact form values
   */
  const onBlur = (field: string, value: string) =>
    setErrors(
      field === "message"
        ? {
            email: errors.email,
            message: !value ? "Veuillez compléter votre demande" : undefined
          }
        : {
            email: validateEmail(value)
              ? undefined
              : "Votre adresse email n'est pas valide",
            message: errors.message
          }
    );

  return (
    <Container id="contact">
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Formulaire de contact</title>
      </Helmet>
      <React.Fragment>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          gutterBottom={true}
          color={"primary"}
          className={classes.title}
        >
          {" "}
          Contact{" "}
        </Typography>
        <Typography variant="subtitle1" align="center" color="textPrimary">
          Nous sommes à votre disposition pour répondre à vos questions et vous
          accompagner. Contactez-nous !
        </Typography>
        <form
          name="frm"
          className={classes.form}
          onSubmit={handleSubmit}
          noValidate={true}
        >
          <Typography> Email* </Typography>
          <TextField
            ref={emailInputRef}
            id="email"
            value={contact.email}
            placeholder="email@domain.com"
            onChange={event => onChange("email", event.target.value)}
            onBlur={event => onBlur("email", event.target.value)}
            onFocus={event => handleFocus(event)}
            margin="normal"
            variant="outlined"
            className={classes.input}
            error={!!errors.email}
            helperText={errors.email}
            required={true}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline
              }
            }}
          />
          <Typography> Nature de la demande* </Typography>
          <TextField
            id="type"
            select={true}
            margin="normal"
            variant="outlined"
            className={classes.input}
            value={contact.type}
            onChange={event => onChange("type", event.target.value)}
            required={true}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline
              }
            }}
          >
            {CONTACT_TYPES.map((contact, index) => (
              <MenuItem key={index} value={contact.type}>
                {contact.type}
              </MenuItem>
            ))}
          </TextField>
          <Typography> Message* </Typography>
          <TextField
            ref={messageInputRef}
            id="message"
            placeholder="Votre message ici"
            value={contact.message}
            onChange={event => onChange("message", event.target.value)}
            onBlur={event => onBlur("message", event.target.value)}
            onFocus={event => handleFocus(event)}
            multiline={true}
            rows="8"
            margin="normal"
            variant="outlined"
            className={classes.input}
            required={true}
            error={!!errors.message}
            helperText={errors.message}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline
              }
            }}
          />
          <div className={classes.buttonarea}>
            <Grid
              container={true}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                color="primary"
                type="submit"
                fullWidth={true}
                variant="contained"
                className={classes.button}
              >
                {" "}
                Envoyer{" "}
              </Button>
              {isContactSent ? (
                <Typography color={"primary"} variant="h6">
                  Votre message a bien été envoyé!
                </Typography>
              ) : null}
            </Grid>
          </div>
        </form>
      </React.Fragment>
    </Container>
  );
};

export default memo(Contact);
