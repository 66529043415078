// React
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";

// Images
import infoIcon from "../../assets/ic-info.svg";

/**
 * Use styles
 */
const useStyles = makeStyles(() => ({
  subtitle: {
    fontStyle: "italic",
    letterSpacing: "normal",
    color: "#818797",
    maxWidth: 620,
    textAlign: "center"
  },
  basicskill: {
    fontWeight: 600,
    cursor: "pointer",
    textDecoration: "underline"
  },
  title: {
    fontWeight: 600,
    letterSpacing: "normal",
    maxWidth: 620,
    textAlign: "center"
  }
}));

/**
 * No result props
 */
interface NoResultProps {
  titleCustom?: string;
  subTitleCustom?: string;
}

/**
 * Route props
 */
interface RouteInfo {
  domainSlug: string;
}

interface RouteNoResultBasicSkill extends RouteComponentProps<RouteInfo> {}

/**
 * No result component
 */
const NoResult = (props: RouteNoResultBasicSkill & NoResultProps) => {
  const classes = useStyles();
  const { match, history, titleCustom, subTitleCustom } = props;
  let { domainSlug } = match.params;
  // Cas d'un sujet vide
  //const regex = "categorie/(.*)/competences";
  //id = match.url.includes("competences") ? match.url.match(regex)?.[1] : id;

  /** Title */
  const title: string = titleCustom
    ? titleCustom
    : `Il n'y a actuellement aucune mise à jour ou nouvelle activité dans 
  la rubrique « Actualités » de ce domaine`;
  /** Subtitle */
  const subtitle: string = subTitleCustom
    ? subTitleCustom
    : `Vous pouvez néanmoins vous former avec les leçons et 
  les quiz toujours disponibles dans le domaine`;
  /** BasicSkill link */
  const basicskill: string = `« <span class="${classes.basicskill}">Socle de compétences</span> »`;

  /** Handle basic skill redirection */
  const handleBasicSkill = () => {
    history.push({
      pathname: "/" + domainSlug + "/competences"
    });
  };

  return (
    <Grid
      container={true}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <img src={infoIcon} alt="information icone" />
      <Typography variant="h6" gutterBottom={true} className={classes.title}>
        {title}
      </Typography>
      <Typography component="p" className={classes.subtitle}>
        {subtitle}&nbsp;
        {subtitle === " " || subTitleCustom || titleCustom ? null : (
          <Typography
            component="a"
            className={classes.subtitle}
            onClick={() => handleBasicSkill()}
            dangerouslySetInnerHTML={{ __html: basicskill }}
          />
        )}
      </Typography>
    </Grid>
  );
};

export default withRouter(React.memo(NoResult));
