/** Constante */
import { getStandaloneApolloClient } from "./apollo";
import { gql } from "@apollo/client";

/**
 * Get category title
 * @param idOrPath
 * @param message : MessageContact
 */
export const getCategoryTitle = async (idOrPath: string): Promise<string> => {
  const client = await getStandaloneApolloClient();
  const { data } = await client.query({
    query: gql`
      query categoryTitle($idOrPath: String!) {
        category(idOrPath: $idOrPath) {
          title
        }
      }
    `,
    variables: {
      idOrPath: idOrPath
    },
    errorPolicy: "ignore"
  });

  return data?.category?.title ?? "";
};
