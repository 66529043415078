// React
import React from "react";

// Material
import { withStyles, CircularProgress } from "@material-ui/core";

/**
 * Circular dark progress
 */
const CustomDarkCircularProgress = withStyles({
  root: {
    color: "#000"
  }
})(CircularProgress);

/**
 * Circular ligth progress
 */
const CustomLigthCircularProgress = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(CircularProgress);

/**
 * ColorCircularProgress props
 */
interface IColorCircularProgressProps {
  ligth?: boolean;
}

/**
 * ColorCircularProgress component
 */
const ColorCircularProgress = (props: IColorCircularProgressProps) => {
  /** Props */
  const { ligth } = props;

  return ligth ? (
    <CustomLigthCircularProgress size={17} thickness={5} />
  ) : (
    <CustomDarkCircularProgress size={17} thickness={5} />
  );
};

export default React.memo(ColorCircularProgress);
