import { LearningTypes } from "@dsk-lib/user";
import React from "react";
import io from "socket.io-client";

interface TimeProps {
  learningProduct: string;
  url: string;
  userID?: string;
  activityId: string;
  categoryId: string;
  learningType?: LearningTypes;
}

let socket: SocketIOClient.Socket | undefined;

const connect = (b: boolean, timeProps: TimeProps) => {
  if (b === socket?.connected) {
    return;
  }

  b ? start(timeProps) : stop();
};

const stop = () => {
  if (socket) {
    socket.close();
    console.info("WebSocket Client has disconnected");
  }
};

const start = ({ url, ...time }: TimeProps) => {
  socket = io(url);
  console.info("WebSocket Client Connected");
  socket.emit("start", time);
};

export const useTime = ({
  url,
  ...time
}: TimeProps): ((b: boolean) => void) => {
  React.useEffect(() => {
    if (time.userID && time.learningProduct) {
      stop();
      start({
        url,
        ...time
      });
      return stop;
    }
    console.warn("[TIME] No userId id or learningProduct set");
    return;
  }, [
    time.learningProduct,
    time.userID,
    time.activityId,
    time.categoryId,
    time.learningType,
  ]);

  return (b: boolean) => connect(b, { url, ...time });
};
