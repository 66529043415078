interface GetDocumentPagesOptions {
  scale?: number;
  numberTest?: number;
  url: string;
}
export interface ReturnTest {
  canvasURLs: string[];
  numPages: number;
}

export default async ({
  scale = 1,
  numberTest,
  url
}: GetDocumentPagesOptions): Promise<ReturnTest> => {
  let pdf: any;

  try {
    pdf = await window.pdfjsLib.getDocument(url).promise;
  } catch (e) {
    throw new Error();
  }

  const { numPages } = pdf;

  const canvasURLs = [];
  const page = await pdf.getPage(numberTest ? numberTest : 1);
  const viewport = page.getViewport(scale);
  const { width, height } = viewport;
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  canvas.className = "page";
  await page.render({
    canvasContext: canvas.getContext("2d"),
    viewport
  });

  canvasURLs.push(canvas.toDataURL());

  return {
    canvasURLs,
    numPages
  };
};
