import React, { useEffect, useState } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";

import Link, { LinkProps } from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

// Styles
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import "./BreadCrumb.scss";

// Icons
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

// Libs
import { getCategoryTitle } from "../../services/navigation.service";
import { getDomainById } from "../../helpers/domain.helper";
import Scrollbar from "react-scrollbars-custom";
import { DOMAINS } from "../../models/domain.model";

/**
 * Link router props
 */
interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      //  flexWrap: 'wrap',
      marginBottom: 2,
    },
    paper: {
      height: 47,
      padding: theme.spacing(2, 1, 2, 1),
    },
  })
);

/**
 * Link router
 */
const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

/**
 * BreadCrumb Interface
 */
interface BreadCrumb {
  key: string;
  value: string;
}

/**
 * Breadcrumb Name map
 */
const breadcrumbNameMap: BreadCrumb[] = [
  { key: "a-la-une", value: "À la une" },
  { key: "mention", value: "Mentions légales" },
  { key: "contact", value: "Contact" },
  { key: "admin", value: "Mes collaborateurs" },
];

/**
 * BreadCrumb
 */
const BreadCrumb = withRouter(({ history }) => {
  /** Classes */
  const classes = useStyles();

  /** Link list */
  const [linkList, setLinkList] = useState<string[]>([]);

  /** Pathnames */
  const { pathname } = history.location;

  /**
   * Use effect
   */
  useEffect(() => {
    /** Pathname list */

    const pathnameList = pathname.split("/").filter((item) => item);
    const currentDomain = DOMAINS.find((item) => item.slug === pathnameList[0]);
    if (currentDomain) {
      const fetchUrlName = async () => {
        const domainName = currentDomain.title;
        if (pathnameList[1] === "actualites") {
          setLinkList([domainName, "Actualités"]);
          if (pathnameList[2] === "dossier-actualites") {
            setLinkList([domainName, "Actualités", `Dossier d'actualités`]);
          }
        } else if (pathnameList[1] === "competences") {
          setLinkList([domainName ?? "test", "Socle de compétences"]);
          if (pathnameList[2]) {
            const result = await getCategoryTitle(pathnameList[2]);
            setLinkList([
              domainName ?? "test",
              "Socle de compétences",
              result ?? "subject",
            ]);
          }
        }
      };
      fetchUrlName();
    } else if (pathnameList[0] === "a-la-une") {
      setLinkList(["À la une"]);
      if (pathnameList[1] === "dossier-actualites") {
        setLinkList(["À la une", `Dossier d'actualités`]);
      }
    } else {
      const name = getBreadCrumbName(pathnameList);
      if (name) {
        setLinkList([name.value]);
      } else {
        setLinkList([]);
      }
    }
  }, [pathname]);

  /**
   * Get breadCrumb name
   */
  const getBreadCrumbName = (pathnames: string[]): BreadCrumb | undefined => {
    return breadcrumbNameMap.find((item: BreadCrumb) => {
      return pathnames[0] === item.key;
    });
  };

  /**
   * Get url
   */
  const getUrl = (path: string): string => {
    const pathList = path.split("/");
    const indexCompetence = pathList.findIndex((item) => {
      return item === "competences" || item === "actualites";
    });
    const pathSlice = pathList.slice(0, indexCompetence + 1);
    return pathSlice.join("/");
  };

  return (
    <div className={classes.root}>
      <Paper
        hidden={0 === linkList.length}
        elevation={2}
        className={classes.paper}
        square={true}
      >
        <Scrollbar style={{ height: 33 }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="Breadcrumb"
            className="Breadcrumbs"
          >
            {linkList.map((value: string, index: number) => {
              const last = index === linkList.length - 1;

              return last ? (
                <Typography color="textPrimary" key={index + value}>
                  {linkList[index]}
                </Typography>
              ) : value === "Socle de compétences" ? (
                <LinkRouter
                  color="inherit"
                  to={getUrl(pathname)}
                  key={index + value}
                >
                  {linkList[index]}
                </LinkRouter>
              ) : value === "Actualités" ? (
                <LinkRouter
                  color="inherit"
                  to={getUrl(pathname)}
                  key={index + value}
                >
                  {linkList[index]}
                </LinkRouter>
              ) : value === "À la une" ? (
                <LinkRouter
                  color="inherit"
                  to={"/a-la-une"}
                  key={index + value}
                >
                  {linkList[index]}
                </LinkRouter>
              ) : (
                <Typography color="inherit" key={index + value}>
                  {linkList[index]}
                </Typography>
              );
            })}
          </Breadcrumbs>
        </Scrollbar>
      </Paper>
    </div>
  );
});

export default BreadCrumb;
