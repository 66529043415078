interface IVideoCurrentTimes {
  [key: string]: number;
}

const VIDEO_CURRENT_TIMES_LOCAL_STORAGE_KEY = "videosCurrentTimes";

const storeVideoCurrentTimes = (currentTimes: IVideoCurrentTimes): void => {
  window.localStorage.setItem(
    VIDEO_CURRENT_TIMES_LOCAL_STORAGE_KEY,
    JSON.stringify(currentTimes)
  );
};

const getVideosCurrentTimes = (): IVideoCurrentTimes => {
  return JSON.parse(
    window.localStorage.getItem(VIDEO_CURRENT_TIMES_LOCAL_STORAGE_KEY) || "{}"
  );
};

export const storeVideoCurrentTime = (
  videoId: string,
  currentTime: number
): void => {
  const times: IVideoCurrentTimes = getVideosCurrentTimes();
  times[videoId] = currentTime;
  storeVideoCurrentTimes(times);
};

export const deleteVideoCurrentTime = (videoId: string): void => {
  const times: IVideoCurrentTimes = getVideosCurrentTimes();
  delete times[videoId];
  storeVideoCurrentTimes(times);
};

export const getVideoCurrentTime = (videoId: string): number => {
  return getVideosCurrentTimes()[videoId];
};
