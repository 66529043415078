import React from "react";
// Lib
import { AutoSizer } from "react-virtualized";
// Material
import { Grid, CircularProgress } from "@material-ui/core";
// Style
import {
  createTheme,
  makeStyles,
  MuiThemeProvider,
  useTheme,
  createStyles
} from "@material-ui/core/styles";

const themeRF = createTheme({
  palette: {
    primary: {
      main: "#77C35E"
    }
  },
  overrides: {
    MuiToolbar: {
      regular: {
        height: "56px",
        minHeight: "56px"
      }
    }
  }
});

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end"
    },
    viewContainer: {
      flexGrow: 1,
      padding: theme.spacing(0)
    },
    content: {
      margin: "0 auto",
      paddingBottom: 20,
      minWidth: "90%",
      height: "calc(100vh  - 105px)"
    }
  })
);

/**
 * ServiceUnvailable component
 */
const OverlayLoader = () => {
  /** Classes */
  const classes = useStyles();
  /** Theme */
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={themeRF}>
        <main className={classes.viewContainer}>
          <div className={classes.drawerHeader} />
          <div className={classes.content}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <Grid
                    container={true}
                    style={{ width, height, padding: theme.spacing(3) }}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Grid>
                );
              }}
            </AutoSizer>
          </div>
        </main>
      </MuiThemeProvider>
    </div>
  );
};

export default OverlayLoader;
