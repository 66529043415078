import React from "react";
import { Route, Redirect, RouteProps, RouteComponentProps } from "react-router";

// Services
import { getIsAdmin } from "../../services/user.service";

export const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  if (!component) {
    throw Error("component is undefined");
  }

  const Component = component; // JSX Elements have to be uppercase.
  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    if (getIsAdmin() === true) {
      return <Component {...props} />;
    }
    return <Redirect to={{ pathname: "/noaccess" }} />;
  };

  return <Route {...rest} render={render} />;
};
