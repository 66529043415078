/**
 * This function throw error if it's different to list in responseAuthorized
 * @param {Response} response
 */
export const throwErrorResponse = (response: Response) => {
  const responseAuthorized: Array<number> = [200, 201];
  if (!responseAuthorized.includes(response?.status)) {
    throw response;
  }
};
