// React
import React, { useEffect, useState } from "react";
// Material
import {
  Theme,
  Typography,
  Paper,
  Grid,
  Hidden,
  Fab,
  Button,
  Container
} from "@material-ui/core";
// Icons
import { ArrowForward } from "@material-ui/icons";
// Styles
import { createStyles, makeStyles } from "@material-ui/styles";
import { handleBilanColor } from "../../../helpers/color-translator.helper";
// Components
import QuizAnswerProgress from "./QuizAnswerProgress/QuizAnswerProgress";
import BilanThemeCard from "./BilanThemeCard/BilanThemeCard";
// Models
import { Quiz, QuizProgress } from "../../../graphql";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: "white",
      textTransform: "none",
      fontSize: 16,
      [theme.breakpoints.up("md")]: {
        margin: 10
      }
    },
    closeBtn: {
      color: "white",
      backgroundColor: "#77C35E"
    },
    bilanHeaderTitle: {
      fontSize: 18,
      lineHeight: 2.33,
      paddingTop: 8,
      marginTop: -25,
      zIndex: 3,
      marginBottom: 30,
      backgroundColor: "#f1f4f5",
      fontWeight: "bold",
      position: "relative"
    },
    themeLabel: {
      fontWeight: "bold",
      display: "inline-block",
      marginRight: 15,
      [theme.breakpoints.down("sm")]: {
        display: "block"
      }
    },
    pagerContainer: {
      width: 960,
      maxWidth: "100%",
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down("sm")]: {}
    },
    pagerNavigation: {
      [theme.breakpoints.down("sm")]: {
        height: "100%"
      },
      [theme.breakpoints.up("md")]: {
        transform: "translateY(-50%)"
      }
    }
  })
);

const formatCorrectAnswerPercentage = (
  validatedScore: number,
  maximalScore: number
): number => {
  return maximalScore === 0
    ? 0
    : parseInt(((100 * validatedScore) / maximalScore).toFixed(0));
};

/**
 * Bilan props
 */
interface IStarterBilanProps {
  quiz: Quiz;
  handleClose: () => void;
  score: number | null;
}

/**
 * Bilan Component
 */
const StarterBilan = ({ quiz, handleClose, score }: IStarterBilanProps) => {
  /** classes */
  const classes = useStyles();
  const [scorePercentage, setScorePercentage] = useState<number>(0);

  useEffect(() => {
    setScorePercentage(
      formatCorrectAnswerPercentage(score ?? 0, quiz.maximalScore)
    );
  }, [score]);

  return (
    <Container maxWidth="md">
      <QuizAnswerProgress progress={+scorePercentage} />
      <Typography
        component="p"
        align="center"
        className={classes.bilanHeaderTitle}
        style={{
          color: handleBilanColor(+scorePercentage)
        }}
      >
        Vous avez eu {scorePercentage}% de bonnes réponses
      </Typography>

      <div style={{ paddingBottom: 30 }}>
        {quiz.exerciseListData &&
          quiz.exerciseListData.map((exercise, index) => (
            <BilanThemeCard
              index={index}
              key={index}
              exercise={exercise}
              bookmarks={
                quiz.bookmark?.filter(
                  (bookmark): bookmark is QuizProgress =>
                    bookmark?.current_question_id === exercise?.learningId
                ) ?? []
              }
            />
          ))}
      </div>
      <Paper
        elevation={3}
        style={{
          position:
            navigator.platform === "iPad" || "iPhone" ? "fixed" : "absolute",
          bottom: 0,
          height: "70px",
          left: 0,
          right: 0
        }}
      >
        <div className={classes.pagerContainer}>
          <Grid item={true} xs={true} className={classes.pagerNavigation}>
            <Hidden mdUp={true}>
              <Grid
                container={true}
                justifyContent="center"
                alignItems="stretch"
                style={{ height: "100%" }}
              >
                <Grid
                  item={true}
                  container={true}
                  xs={6}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    color="primary"
                    className={`${classes.button} ${classes.closeBtn}`}
                    onClick={handleClose}
                  >
                    &nbsp;Démarrer ma formation
                    <ArrowForward />
                  </Button>
                </Grid>
              </Grid>
            </Hidden>

            <Hidden smDown={true}>
              <Grid
                container={true}
                justifyContent="center"
                alignItems="center"
              >
                <Fab
                  variant="extended"
                  size="large"
                  className={`${classes.button} ${classes.closeBtn}`}
                  onClick={handleClose}
                >
                  &nbsp;Démarrer ma formation <ArrowForward />
                </Fab>
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </Paper>
    </Container>
  );
};

export default React.memo(StarterBilan);
