// React
import React from "react";

// Material
import { Box } from "@material-ui/core";

// Models
import { Activity } from "../../models/Activity.model";

// Components
import CardActivity from "../CardActivity/CardActivity";

/**
 * ActivityListProps props
 */
interface ActivityListProps {
  list: Activity[];
  locked?: boolean;
  onLockedClick?: () => void;
}

/**
 * ActivityList component
 */
const ActivityList = ({ list, locked, onLockedClick }: ActivityListProps) => (
  <div id="activityList">
    {list.map((activity: Activity) => (
      <Box key={activity._id} id={activity._id} marginTop={1}>
        <CardActivity
          locked={locked}
          onLockedClick={onLockedClick}
          activity={activity}
        />
      </Box>
    ))}
  </div>
);

export default React.memo(ActivityList);
