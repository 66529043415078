export function isIE(): boolean {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  if (msie > 0 || isIE11()) {
    // IE 10 or older => return version number
    return true;
  }
  return false;
}

function isIE11(): boolean {
  return !!navigator.userAgent.match(/Trident.*rv[ :]*11\./);
}
