/**
 * Constante
 */
export const CONST = {
  CURRENT_YEAR: new Date().getFullYear(),
  KEYCLOAK: {
    URL: process.env.REACT_APP_KEYCLOAK_URL || "",
    REALM: process.env.REACT_APP_KEYCLOAK_REALM || "",
    CLIENTID: process.env.REACT_APP_KEYCLOAK_CLIENTID || ""
  },
  TIME_ENDPOINT: process.env.REACT_APP_TIME_ENPOINT || "",
  RF_CAC_URL: process.env.REACT_APP_URL_RF_CAC,
  GRAPHQL_ENDPOINT:
    process.env.REACT_APP_GRAPHQL_ENDPOINT || "http://localhost:4000/graphql",
  ALICE_ADAPTER_ENDPOINT:
    process.env.REACT_APP_ALICE_ADAPTER_ENDPOINT ||
    "http://localhost:3002/users",
  OKULUS_URL: process.env.REACT_APP_OKULUS_URL || "http://localhost:4000",
  PDF_GENERATOR_ENDPOINT:
    process.env.REACT_APP_PDF_GENERATOR_ENDPOINT || "http://localhost:3001",
  PAGE_PRODUCTLIST: "https://portail.rf-elearning.com/",
  MONTH_REDUCED: 3,
  GA_ID: process.env.REACT_APP_GA_ID || "UA-110057889-2",
  SURVEY_ID: process.env.REACT_APP_SURVEY_ID || "Wsv3c4ai",
  DOMAIN_ACCOUNTING: process.env.REACT_APP_DOMAIN_ACCOUNTING ?? '63c121bb5e7ca8bae66a8bbb',
  DOMAIN_TAX: process.env.REACT_APP_DOMAIN_TAX ?? '63c121bb5e7ca8bae66a8bc6',
  DOMAIN_SOCIAL: process.env.REACT_APP_DOMAIN_SOCIAL ?? '63c121bb5e7ca8bae66a8bd1',
  DOMAIN_PAYROLL: process.env.REACT_APP_DOMAIN_PAYROLL ?? '63c121bb5e7ca8bae66a8bdc',
  DOMAIN_BUSINESS: process.env.REACT_APP_DOMAIN_BUSINESS ?? '63c121bb5e7ca8bae66a8be7',
  DOMAIN_HERITAGE: process.env.REACT_APP_DOMAIN_HERITAGE ?? '63c121bb5e7ca8bae66a8bf3',
  DOMAIN_OFFICE: process.env.REACT_APP_DOMAIN_OFFICE ?? '63c121bb5e7ca8bae66a8bfe',
};
