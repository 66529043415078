import { Reducer } from "redux";
import { StarterQuizList } from "../../models/Activity.model";
import {
  REMOVE_STARTER_QUIZ,
  starterQuizListActions,
  STORE_STARTER_QUIZ_LIST
} from "./action";

const initialState: StarterQuizList = {};

const reducer: Reducer<any, starterQuizListActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case REMOVE_STARTER_QUIZ:
      return {
        ...state,
        [action.payload]: undefined
      };
    case STORE_STARTER_QUIZ_LIST:
      return action.payload;
    default:
      return state;
  }
};

export { reducer as starterQuizListReducer };
