import { Reducer } from "redux";
import { errorAction } from "./actions";
import { SET_ERROR } from "./types";

export interface IErrorHandler {
  isError: boolean;
  code?: number;
}

const initialState: IErrorHandler = {
  isError: false,
  code: undefined
};

const reducer: Reducer<any, errorAction> = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export { reducer as errorHandlerReducer };
