// Constante
import { CONST } from "../config/constant";

/**
 * getDateReducedMonths
 */
export const getDateReducedMonths = () => {
  const dateReducedMonths = new Date();
  dateReducedMonths.setMonth(
    dateReducedMonths.getMonth() - CONST.MONTH_REDUCED
  );

  return dateReducedMonths;
};

/**
 * getTimestamp from date
 */
export const getTimestamp = (date: Date) => {
  return Math.floor(date.getTime() / 1000);
};

/**
 * Get date first date of year
 */
export const getFirstDateOfYear = (year: number) => {
  return new Date(year, 0, 1, 0, 0, 0);
};

/**
 * Get date last date of year
 */
export const getLastDateOfYear = (year: number) => {
  return new Date(year, 11, 31, 23, 59, 59);
};
