import React, { useEffect, useState } from "react";
import "./Viewers.scss";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import getDocumentPages from "../../helpers/getDocumentPages";
import NavigateNextOutlinedIcon from "@material-ui/icons/NavigateNextOutlined";
import NavigateBeforeOutlinedIcon from "@material-ui/icons/NavigateBeforeOutlined";
import { setErrorToHandleError } from "../../store/errorHandler/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: "#f1f4f5"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    }
  })
);
export default ({ url }: any) => {
  const classes = useStyles();
  const [numberCurrentPage, setNumberCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<any>([]);
  const dispatch = useDispatch();

  const getPages = async () => {
    try {
      const { canvasURLs, numPages } = await getDocumentPages({
        url,
        numberTest: numberCurrentPage
      });

      setPages(canvasURLs);
      setTotalPages(numPages);
    } catch (e) {
      dispatch(setErrorToHandleError(true));
    }
  };

  useEffect(() => {
    getPages();
  }, [url, numberCurrentPage]);

  const nextPage = () => {
    if (numberCurrentPage >= totalPages) {
      return;
    }

    setNumberCurrentPage(numberCurrentPage + 1);
  };

  const backPage = () => {
    if (numberCurrentPage <= 1) {
      return;
    }
    setNumberCurrentPage(numberCurrentPage - 1);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="viewer">
            {pages.map((canvasURL: any, idx: number) => (
              <img src={canvasURL} key={idx} />
            ))}
          </div>
          <div className="box">
            <div>
              {/*         <ZoomInOutlinedIcon style={{fontSize: 40}}/>
              <ZoomOutOutlinedIcon style={{fontSize: 40}}/>*/}
              <NavigateBeforeOutlinedIcon
                onClick={backPage}
                style={{ fontSize: 40 }}
              />
            </div>
            <div style={{ color: "white" }}>
              {numberCurrentPage}/{totalPages}
            </div>
            <div>
              <NavigateNextOutlinedIcon
                onClick={nextPage}
                style={{ fontSize: 40 }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
