import { User } from "../../models/user.model";

export interface FetchUser {
  type: ActionTypes.fetchUser;
  payload: User | null;
}

export interface FetchUserPartial {
  type: ActionTypes.fetchUserPartial;
  payload: Partial<User>;
}

export type Action = FetchUser | FetchUserPartial;

export enum ActionTypes {
  fetchUser,
  fetchUserPartial
}

export const setUserSuccess = (user: User): FetchUser => {
  return {
    type: ActionTypes.fetchUser,
    payload: user
  };
};

export const setUserPartial = (user: Partial<User>): FetchUserPartial => {
  return {
    type: ActionTypes.fetchUserPartial,
    payload: user
  };
};
