// React
import React from "react";

// Material
import { Paper, Grid } from "@material-ui/core";

// Styles
import { makeStyles, createStyles } from "@material-ui/styles";

// Helpers
import { handleBilanColor } from "../../../../helpers/color-translator.helper";
import { Exercise, QuizProgress } from "../../../../graphql";

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    bilanBlocCardContainer: {
      padding: 24,
      marginBottom: 8
    },
    themeLabel: {
      fontWeight: "bold",
      fontSize: 16,
      color: "#18202f",
      marginRight: 10
    },
    themeName: {
      fontSize: 16,
      color: "#18202f",
      letterSpacing: 0.5
    },
    progressBg: {
      height: 4,
      borderRadius: 20,
      overflow: "hidden",
      backgroundColor: "#dddfdf",
      marginRight: 10
    },
    progressLine: {
      height: "100%"
    },
    progressValue: {
      fontSize: 12,
      fontWeight: 600,
      color: "#18202f"
    }
  })
);

/**
 * BilanBlocCardTheme props
 */
interface IBilanThemeCardProps {
  index: number;
  exercise: Exercise | null;
  bookmarks: QuizProgress[];
}

/**
 * BilanBlocCardTheme Component
 */
const BilanThemeCard = ({
  index,
  exercise,
  bookmarks
}: IBilanThemeCardProps) => {
  /** classes */
  const classes = useStyles();
  const progress =
    bookmarks.length && exercise?.questionsData
      ? ((bookmarks[bookmarks.length - 1].score ?? 0) * 100) /
        exercise?.questionsData.length
      : 0;

  return (
    <Paper component="div" className={classes.bilanBlocCardContainer}>
      <Grid container={true}>
        <Grid container={true} style={{ marginBottom: 8 }}>
          <Grid item={true} xs={"auto"}>
            <span className={classes.themeLabel}>Thème {index}</span>
          </Grid>
          <Grid item={true} xs={"auto"}>
            <span className={classes.themeName}>
              {exercise?.text?.replace(/(<([^>]+)>)/gi, "") ?? ""}
            </span>
          </Grid>
        </Grid>
        <Grid container={true} alignItems="center">
          <Grid item={true} xs={5} className={classes.progressBg}>
            <div
              className={classes.progressLine}
              style={{
                backgroundColor: handleBilanColor(progress),
                width: `${progress}%`
              }}
            />
          </Grid>
          <Grid item={true} xs={"auto"}>
            <span className={classes.progressValue}>{progress}%</span>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default React.memo(BilanThemeCard);
