import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";

// Material:
import { Box, Grid, Theme, createStyles, makeStyles } from "@material-ui/core";

// Store
import { useSelector } from "react-redux";
import { StoreState } from "../../store";

// Components
import { FakeCardActivity } from "../../features/CardActivity/CardActivity";


// Model

import { User } from "../../models/user.model";

// Keycloak

import { getDomainById } from "../../helpers/domain.helper";
import { FakeCardFolder } from "../../features/CardFolder/CardFolder";
//import { useNewsLazyQuery } from "../../graphql";

// Assets
//import bandeau from "../../assets/Année_2024@2x.png";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "10%",
        paddingRight: "10%"
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: 40,
        paddingRight: 40
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0
      },
      ["@supports (-webkit-touch-callout: none)"]: {
        marginBottom: 149
      }
    },
    bandeau: {
      width: "100%",
      marginBottom: 12
    }
  })
);

/**
 * Home component
 */
const Home = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  /** Folder state   */

  /** Activity list state */

  /** GraphQL */
/*   const [getHome, { data, error }] = useNewsLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "cache-and-network"
  }); */
  /** Processing time state */
  const user: User | null = useSelector((state: StoreState) => state.user);
  /** Loading states */
  const [loadingActivities] = useState(true);
  const [loadingFolder] = useState(true);

  /**
   * Fetch Home Data
   */
  const fetchData = () => {
    // Only show activities created or updated 3 months before
    const newsLimitDate = new Date();
    newsLimitDate.setMonth(newsLimitDate.getMonth() - 3);
  };

  /**
   * Use effect get activity list
   */
  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      fetchData();
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, []);


  useEffect(() => {
    if (
      user?.isBlended &&
      !user.subscription.fullDomainsAccessEl.length &&
      user.subscription.domainsAccessEl.length
    ) {
      const firstDomain = getDomainById(user.subscription.domainsAccessEl[0]);
      history.push("categorie/" + firstDomain.slug + "/competences");
    }
  }, [user]);

  return (
    <div>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>À la une</title>
      </Helmet>
      <Grid
        container={true}
        direction="column"
        spacing={2}
        className={classes.container}
      >
        {/*  <img
          src={bandeau}
          alt="Nous vous souhaitons bonne fete"
          className={classes.bandeau}
        /> */}
      </Grid>
      {loadingFolder ? (
        <Box marginBottom={15}>
          <FakeCardFolder />
        </Box>
      ) : null}
      {loadingActivities ? (
        <Box marginTop={3}>
          <FakeCardActivity />
        </Box>
      )  : null}
    </div>
  );
};

export default Home;
