import React, { ReactNode } from "react";

/** Action */
export interface Action {
  type: "setScrollPosition";
  scrollPosition: number;
}
/** init state */
const ScrollStateContext = React.createContext({ scrollPositionSelected: 0 });
/** init dispatch */
const ScrollDispatchContext = React.createContext(
  (() => 0) as React.Dispatch<Action>
);

/** Reducer */
function ScrollReducer(
  state: { scrollPositionSelected: number },
  action: Action
) {
  switch (action.type) {
    case "setScrollPosition": {
      return { scrollPositionSelected: action.scrollPosition };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}${state}`);
    }
  }
}
/**
 * IProps
 */
interface IProps {
  children: ReactNode;
}
/**
 * Scroll provider
 * @param children
 */
function ScrollProvider({ children }: IProps) {
  const [state, dispatch] = React.useReducer(ScrollReducer, {
    scrollPositionSelected: 0
  });
  return (
    <ScrollStateContext.Provider value={state}>
      <ScrollDispatchContext.Provider value={dispatch}>
        {children}
      </ScrollDispatchContext.Provider>
    </ScrollStateContext.Provider>
  );
}
/** use Scroll state */
function useScrollState() {
  const context = React.useContext(ScrollStateContext);
  if (context === undefined) {
    throw new Error("useScrollState must be used within a ScrollProvider");
  }
  return context;
}
/**  use Scroll dispatch */
function useScrollDispatch() {
  const context = React.useContext(ScrollDispatchContext);
  if (context === undefined) {
    throw new Error("useScrollDispatch must be used within a ScrollProvider");
  }
  return context;
}

export { ScrollProvider, useScrollState, useScrollDispatch };
