/**
 * Get bilan color
 * @param success
 */
export const handleBilanColor = (success: number): string => {
  return success > 75
    ? "#77C35E"
    : success >= 50 && success <= 75
    ? "#f39200"
    : "#ee3547";
};
