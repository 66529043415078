/** Constante */
import { CONST } from "../config/constant";
import { Status } from "../graphql";
import { throwErrorResponse } from "../helpers/http.helper";
import { Activity } from "../models/Activity.model";
import { Category } from "../models/category.model";

/** Models */
import {
  User,
  AccessTree,
  AccessTreeItem,
  Setting
} from "../models/user.model";

/** Store */
import { store } from "../store/createStore";

/**
 * Get full name
 */
export const getFullName = (keycloak: any): string =>
  keycloak!.tokenParsed.name || "";

/**
 * Is admin
 */
export const getIsAdmin = (): boolean => !!store.getState().user?.isAdmin;

/**
 * Is super admin
 */
export const getIsSuperAdmin = (): boolean =>
  !!store.getState().user?.isSuperAdmin;

/**
 * Get user id
 */
export const getUserId = (): string => store.getState().user!._id;

/**
 * Disconnect user
 */
export const disconnectUser = (keycloak: any) => {
  keycloak && keycloak.logout();
};

/**
 * Get user survey setting
 */
export const getSurveySetting = (): Setting | undefined =>
  store.getState().user?.survey;

/**
 * Get user's informations
 */
export const getUser = async (
  fetchWithCredentials: typeof fetch
): Promise<User | null> => {
  /** URL user information from Alice */
  try {
    const response = await fetchWithCredentials(
      `${CONST.ALICE_ADAPTER_ENDPOINT}/users/me`,
      {
        method: "get"
      }
    );
    throwErrorResponse(response);
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const canAccessActivity = (activity: Activity): boolean => {
  const user = store.getState().user;
  const tree = user?.subscription.accessTreeEl;

  if (!user?.isSuperAdmin && activity.status !== Status.Published) {
    return false;
  } else if (tree?.[activity.domain.learningId]?.full) {
    return true;
  } else if (activity.parentCategories?.length) {
    const learningIds = [
      activity.domain.learningId,
      activity.parentCategories[0]?.parent?.parent?.learningId,
      activity.parentCategories[0]?.parent?.learningId,
      activity.parentCategories[0]?.learningId
    ];
    return canAccessCategoryRecursive(learningIds, tree);
  }

  return false;
};

export const canAccessDomain = (domainId: number): boolean =>
  !!store.getState().user?.subscription.accessTreeEl[domainId];

export const canAccessSubject = (subject: Category): boolean => {
  const tree = store.getState().user?.subscription.accessTreeEl;
  const learningIds = [
    subject.domain.learningId,
    subject.parent?.parent?.learningId,
    subject.parent?.learningId,
    subject.learningId
  ];

  return (
    tree?.[subject.domain.learningId]?.full ||
    canAccessCategoryRecursive(learningIds, tree)
  );
};

const canAccessCategoryRecursive = (
  learningIds: (number | undefined)[],
  accessTree?: AccessTree
): boolean => {
  if (accessTree && learningIds[0] && accessTree[learningIds[0]]) {
    const accessTreeItem = accessTree[learningIds[0]];

    return (
      accessTreeItem?.full ||
      canAccessCategoryRecursive(learningIds.slice(1), accessTreeItem.children)
    );
  }

  return false;
};

export const canAccessCategoryFromTree = (
  access: AccessTreeItem,
  learningId: number
): boolean => access.full || !!access.children?.[learningId];

/**
 * Patch survey setting
 */
export const patchSurveySetting = async (
  fetchCredentials: typeof fetch,
  value: boolean
) => {
  return patchSetting(fetchCredentials, "survey", value);
};

/**
 * Patch user setting
 */
export const patchSetting = async (
  fetchCredentials: typeof fetch,
  field: string,
  value: boolean
) => {
  return await fetchCredentials(
    `${CONST.ALICE_ADAPTER_ENDPOINT}/users/me/setting/${field}`,
    {
      method: "PATCH",
      body: JSON.stringify({ value: value }),
      headers: {
        "content-type": "application/json"
      }
    }
  );
};

/**
 * Get colleagues
 */
export const getColleagues = async (
  fetchWithCredentials: typeof fetch
): Promise<User[]> => {
  const response = await fetchWithCredentials(
    `${CONST.ALICE_ADAPTER_ENDPOINT}/users/me/colleagues`,
    {
      method: "get"
    }
  );

  return response.status === 200 ? await response.json() : [];
};
