import { User } from "../../models/user.model";
import { Action, ActionTypes } from "./actions";

export const userReducer = (state: User | null = null, action: Action) => {
  switch (action.type) {
    case ActionTypes.fetchUser:
      return action.payload;
    case ActionTypes.fetchUserPartial:
      return state
        ? {
            ...state,
            ...action.payload
          }
        : null;
    default:
      return state;
  }
};
