import { useMachine } from "@xstate/react";
import React, { FC, useEffect, useMemo } from "react";
import { QuizPlayerContext, quizPlayerStateMachine } from "@digischool/quiz";

// GraphQl types
import {
  Answer,
  BookmarkStatus,
  BookmarkType,
  Exercise,
  Question,
  Quiz as QuizGql,
  useQuizDoneAndTimePostMutation,
  useQuizProgressPostMutation,
} from "../../graphql";

// Material
import {
  Theme,
  Box,
  Button,
  CircularProgress,
  Typography,
  Container,
  Paper,
  LinearProgress,
  Card,
  Dialog,
} from "@material-ui/core";

// Style
import { makeStyles, createStyles } from "@material-ui/styles";
import clsx from "clsx";

// Images
import fabNext from "./assets/fab-next.svg";
import fabValidate from "./assets/fab-validate.svg";
import fabExplain from "./assets/fab-explain.svg";
import fabValidateDisabled from "./assets/fab-validate-disabled.svg";
import icGoodAnswer from "./assets/ic-good-answer.svg";
import icBadAnswer from "./assets/ic-bad-answer.svg";
import { getLastBookmark } from "../../services/activity.service";
import { useSnackbar } from "notistack";
import { getTimestamp } from "../../helpers/date.helper";
import QuizStarterCard from "./QuizStarterCard";
import { Domain } from "../../models/domain.model";
import { getUserId } from "../../services/user.service";
import { Quiz } from "../../models/Activity.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quizContent: {
      padding: "0 10px 75px 10px",
    },
    btn: {
      outline: "none",
      minWidth: "auto",
      boxSizing: "border-box",
      width: "56px",
      height: "56px",
      borderRadius: "28px",
      border: "none",
      padding: "12px 0 0 0",
    },
    btnAction: {
      width: "56px",
      height: "70px",
      border: "none",
    },
    btnNext: {
      background: `url(${fabNext})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    btnValidate: {
      background: `url(${fabValidate})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    btnValidateDisabled: {
      background: `url(${fabValidateDisabled})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    btnExplanations: {
      background: `url(${fabExplain})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    questionText: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: 1.4,
      color: theme.palette.text.primary,
      textAlign: "justify",
      marginBottom: "30px",
      padding: "0 24px",
    },
    exerciseText: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: 1.4,
      color: theme.palette.text.primary,
      textAlign: "justify",
      marginBottom: "30px",
    },
    progress: {
      width: "250px",
      height: 6,
      borderRadius: "20px",
      backgroundColor: theme.palette.grey[200],
      marginBottom: "1px",
    },
    bar: {
      backgroundColor: theme.palette.primary.main,
    },
    label: {
      width: "250px",
      fontSize: 14,
      marginBottom: "7px",
      letterSpacing: "0.3px",
      color: theme.palette.text.primary,
      fontWeight: "normal",
    },
    explanationModal: {
      zIndex: "2100!important" as any,
      padding: "20px",
    },
    explanationContent: {
      padding: "20px",
    },
    explanationTitle: {
      fontWeight: 800,
      fontSize: 24,
      textAlign: "center",
      marginBottom: theme.spacing(2),
      color: "#79a611",
    },
    explanationBtn: {
      margin: "16px 0 5px 0",
      color: "white",
    },
  })
);

const useAnswerStyles = makeStyles(() =>
  createStyles({
    card: {
      cursor: "pointer",
      width: "100%",
      minHeight: "50px",
      borderRadius: "5px",
      boxShadow:
        "0 0 8px 0 rgba(0, 0, 0, 0.05), inset 1px 0 0 0 rgba(0, 0, 0, 0.11)",
      border: "solid 1px #c3c6cc",
      fontWeight: "normal",
      backgroundColor: "transparent",
      display: "flex",
      justifyContent: "space-between",
      paddingRight: "12px",

      "&:not(:first-child)": {
        marginTop: "20px",
      },
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.65)",
      },
    },
    selected: {
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
      borderLeft: "8px solid black",
      backgroundColor: "#ffffff",
    },
    success: {
      border: "solid 1px #79a611",
    },
    error: {
      border: "solid 1px #eb5d5d",
    },
    typography: {
      padding: "12px 25px",
      fontSize: "16px",
      lineHeight: "1.63",
      letterSpacing: "0.3px",
      color: "#263c46",
    },
  })
);

interface AnswerCardProps {
  text: string;
  selected: boolean;
  correction?: boolean;
  onClick: () => void;
}

const AnswerCard: FC<AnswerCardProps> = ({
  text,
  selected,
  correction,
  onClick,
}) => {
  const classes = useAnswerStyles();

  return (
    <Card
      onClick={onClick}
      className={clsx({
        [classes.card]: true,
        [classes.selected]: selected,
        [classes.success]: correction === true,
        [classes.error]: correction === false && selected,
      })}
    >
      <Typography className={classes.typography}>{text}</Typography>
      {correction === true && <img src={icGoodAnswer} alt="" />}
      {correction === false && selected && <img src={icBadAnswer} alt="" />}
    </Card>
  );
};

export interface QuizPlayerProps {
  data: QuizGql;
  quiz: Quiz;
  domain: Domain;
  withStarterCard: boolean;
  isStarter: boolean;
  isDone: boolean;
  onQuizEnd: (score: number) => void;
  onExerciseChange: () => void;
}

const loadSavedQuiz = (id: string): QuizPlayerContext | undefined => {
  const data = window.localStorage.getItem(id);
  if (data) {
    return JSON.parse(data);
  }
  return;
};

const saveQuiz = (id: string, context: QuizPlayerContext): void => {
  window.localStorage.setItem(id, JSON.stringify(context));
};

const deleteSavedQuiz = (id: string): void => {
  window.localStorage.removeItem(id);
};

export const QuizPlayer: FC<QuizPlayerProps> = ({
  data,
  domain,
  withStarterCard,
  isStarter,
  isDone,
  onQuizEnd,
  onExerciseChange,
}) => {
  const [state, send] = useMachine(quizPlayerStateMachine);
  /** classes */
  const classes = useStyles();
  /** GraphQl */
  const [updateBookmark, { error }] = useQuizProgressPostMutation();
  const [updateBookmarkAndTime] = useQuizDoneAndTimePostMutation();
  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    onExerciseChange();
    const savedQuiz = loadSavedQuiz(data._id);
    send({
      type: "START",
      context: savedQuiz
        ? savedQuiz
        : {
            exerciseList: data.exerciseListData as any,
            muted: true,
            showAnswer: true,
            startScore: data.startScore ?? 0,
            timeout: 0,
            seriousFault: 0,
          },
    });
  }, []);

  const currentExercise = useMemo(
    () => state.context.exerciseList[state.context.currentExercise],
    [state.context]
  );

  const handleSelectAnswer = (questionId: string, answerId: string) => () => {
    send({
      type: "SELECT_ANSWER",
      answerId,
      questionId,
    });
  };

  const isAnswerSelected = (
    exercise: Exercise,
    question: Question,
    answer: Answer
  ): boolean => {
    return state.context.answerList[exercise._id][question._id][answer._id];
  };

  const getExerciseScore = (exercise: Exercise): number => {
    const correctAnswers = exercise.questionsData?.filter(
      (q) =>
        q?.possibleAnswers &&
        q.possibleAnswers.some(
          (p) => p?.isCorrect && isAnswerSelected(exercise, q, p)
        )
    );
    return correctAnswers?.length ?? 0;
  };

  const hasExplanations = (exercise: Exercise): boolean => {
    return (
      !!exercise?.questionsData &&
      currentExercise.questionsData.some((q) => !!q?.explanation?.text)
    );
  };

  const isWaitingSelection = (exercise: Exercise): boolean => {
    return Object.entries(state.context.answerList[exercise._id]).some(
      (q) => !Object.entries(q[1]).some((a) => !!a[1])
    );
  };

  const handleHideExplanations = () => {
    send({ type: "HIDE_EXPLANATIONS" });
  };

  const handleShowExplanations = () => {
    send({ type: "SHOW_EXPLANATIONS" });
  };

  const getHtml = (
    value: string
  ): {
    __html: string;
  } => ({
    __html: value,
  });

  const saveProgress = () => {
    saveQuiz(data._id, state.context);
    const lastBookmark = getLastBookmark(data.bookmark ?? [], isStarter);
    const exerciseScore = getExerciseScore(currentExercise as any);

    return updateBookmark({
      variables: {
        id: data._id,
        type: isStarter ? BookmarkType.MockExam : BookmarkType.Standard,
        status:
          lastBookmark?.status === BookmarkStatus.Done ||
          state.context.currentExercise === state.context.exerciseList.length
            ? BookmarkStatus.Done
            : BookmarkStatus.InProgress,
        score: exerciseScore,
        currentQuestionId: currentExercise.learningId,
      },
    });
  };

  const handleNextExercise = () => {
    if (error) {
      saveProgress().then(() => send({ type: "NEXT" }));
    } else {
      send({ type: "NEXT" });
    }
  };

  useEffect(() => {
    if (state.context.finished && !state.context.finishCallbackCalled) {
      if (isStarter || isDone) {
        updateBookmark({
          variables: {
            id: data._id,
            type: isStarter ? BookmarkType.MockExam : BookmarkType.Standard,
            status: BookmarkStatus.Done,
            score: state.context.score ?? 0,
          },
        }).catch(() => {
          enqueueSnackbar(
            "Erreur lors de l'enregistrement de la progression. Veuillez contacter l'administrateur.",
            {
              variant: "error",
            }
          );
        });
      } else {
        const now = getTimestamp(new Date());
        updateBookmarkAndTime({
          variables: {
            id: data._id,
            type: isStarter ? BookmarkType.MockExam : BookmarkType.Standard,
            score: state.context.score ?? 0,
            userID: getUserId(),
            startTimestamp: now,
            endTimestamp: now + (data.estimatedTime ?? 0),
            categoryId: domain.gutenbergId,
          },
        }).catch(() => {
          enqueueSnackbar(
            "Erreur lors de l'enregistrement de la progression. Veuillez contacter l'administrateur.",
            {
              variant: "error",
            }
          );
        });
      }
      deleteSavedQuiz(data._id);
      onQuizEnd(state.context.score ?? 0);
    }
  }, [state.context.finished]);

  useEffect(() => {
    if (state.matches("exercise.showAnswer")) {
      saveProgress();
    } else if (state.matches("exercise.loadingAssets")) {
      onExerciseChange();
    } else if (state.matches("exercise.showExercise.waitingValidation")) {
      saveQuiz(data._id, state.context);
    }
  }, [state.value]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        "Erreur lors de l'enregistrement de la progression. Veuillez réessayer.",
        {
          variant: "error",
        }
      );
    }
  }, [error]);

  return (
    <Container maxWidth="md">
      {withStarterCard && <QuizStarterCard domain={domain} />}
      <div className={classes.quizContent}>
        {["exercise.showAnswer"].some(state.matches) && (
          <Dialog
            open={["exercise.showAnswer.showExplanations"].some(state.matches)}
            onClose={handleHideExplanations}
            className={classes.explanationModal}
            classes={{ paper: classes.explanationContent }}
          >
            <Typography className={classes.explanationTitle}>
              Explications
            </Typography>
            <div
              dangerouslySetInnerHTML={getHtml(
                currentExercise.questionsData[0].explanation.text ?? ""
              )}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleHideExplanations}
              className={classes.explanationBtn}
            >
              Fermer
            </Button>
          </Dialog>
        )}
        {["waiting", "initializeAnswers"].some(state.matches) && (
          <CircularProgress />
        )}
        {state.matches("exercise.loadingAssets") && (
          <Box display="flex" justifyContent="center" mt={4}>
            <Box maxWidth="50%">
              <CircularProgress />
            </Box>
          </Box>
        )}

        {["exercise.showExercise", "exercise.showAnswer"].some(
          state.matches
        ) && (
          <Box>
            {currentExercise.text && (
              <Typography
                className={classes.exerciseText}
                dangerouslySetInnerHTML={{ __html: currentExercise.text }}
              />
            )}
            <Box>
              {currentExercise.questionsData.map((question) => (
                <Box key={`question-${question._id}`}>
                  {question.text && (
                    <Typography
                      className={classes.questionText}
                      dangerouslySetInnerHTML={{ __html: question.text }}
                    />
                  )}
                  <Box my={4}>
                    {question.possibleAnswers.map((answer) => (
                      <AnswerCard
                        key={answer._id}
                        text={answer.text ?? ""}
                        correction={
                          state.matches("exercise.showAnswer")
                            ? answer.isCorrect
                            : undefined
                        }
                        selected={isAnswerSelected(
                          currentExercise as any,
                          question as any,
                          answer as any
                        )}
                        onClick={handleSelectAnswer(question._id, answer._id)}
                      />
                    ))}
                  </Box>
                </Box>
              ))}

              <Box
                display="flex"
                justifyContent="flex-end"
                position={
                  navigator.platform === "iPad" || "iPhone"
                    ? "fixed"
                    : "absolute"
                }
                bottom="39px"
                right="14%"
                zIndex="100"
              >
                {state.matches("exercise.showExercise") && (
                  <Button
                    onClick={() =>
                      !isWaitingSelection(currentExercise as any) &&
                      send({ type: "VALIDATE_ANSWER" })
                    }
                    className={classes.btn}
                  >
                    <div
                      className={`${classes.btnAction} ${
                        isWaitingSelection(currentExercise as any)
                          ? classes.btnValidateDisabled
                          : classes.btnValidate
                      }`}
                    />
                  </Button>
                )}
                {state.matches("exercise.showAnswer") &&
                  hasExplanations(currentExercise as any) && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="128px"
                    >
                      <Button
                        onClick={handleShowExplanations}
                        className={classes.btn}
                      >
                        <div
                          className={`${classes.btnAction} ${classes.btnExplanations}`}
                        />
                      </Button>
                      <Button
                        onClick={handleNextExercise}
                        className={classes.btn}
                      >
                        <div
                          className={`${classes.btnAction} ${classes.btnNext}`}
                        />
                      </Button>
                    </Box>
                  )}
                {state.matches("exercise.showAnswer") &&
                  !hasExplanations(currentExercise as any) && (
                    <Button
                      onClick={handleNextExercise}
                      className={classes.btn}
                    >
                      <div
                        className={`${classes.btnAction} ${classes.btnNext}`}
                      />
                    </Button>
                  )}
              </Box>
            </Box>
            <Paper
              elevation={3}
              style={{
                position:
                  navigator.platform === "iPad" || "iPhone"
                    ? "fixed"
                    : "absolute",
                bottom: 0,
                height: "70px",
                left: 0,
                right: 0,
                margin: "0",
              }}
            >
              {state.matches("exercise") && (
                <Container maxWidth="md" style={{ height: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="left"
                    height="100%"
                    justifyContent="center"
                    margin="0 0 0 34px"
                  >
                    <Typography className={classes.label}>
                      Question : {state.context.currentExercise + 1} /{" "}
                      {state.context.exerciseList.length}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={
                        ((state.context.currentExercise + 1) * 100) /
                        state.context.exerciseList.length
                      }
                      className={classes.progress}
                      classes={{ bar: classes.bar }}
                    />
                  </Box>
                </Container>
              )}
            </Paper>
          </Box>
        )}
      </div>
    </Container>
  );
};
