import React from "react";
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet
} from "@react-pdf/renderer";
import {
  formattedDate,
  getSecondsFormattedOnlyHours
} from "../../helpers/date-fomatter.helper";
import { Domain } from "../../models/domain.model";
import header from "./assets/header.png";
import logoRf from "./assets/Logo-RF.png";
import logoEl from "./assets/rf-e-learning-logo.png";
import signature from "./assets/signature_responsable_mb.png";
import tampon from "./assets/tampon_organisme.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  mainSection: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    padding: "27px 27px",
    fontSize: 11
  },
  section: {
    width: "100%"
  },
  halfSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    alignContent: "center"
  }
});

/**
 * CertificatePicker props
 */
interface ICertificateProps {
  timesPerDomain: { domain: Domain; duration: number }[];
  fullName: string;
  startDate: Date;
  endDate: Date;
}

// Create Document Component
const Certificate = ({
  timesPerDomain,
  fullName,
  startDate,
  endDate
}: ICertificateProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={header} style={{ width: "100%", marginBottom: 20 }} />
        <View style={styles.halfSection}>
          <Image src={logoEl} style={{ width: 115 }} />
        </View>
        <View style={styles.halfSection}>
          <Image src={logoRf} style={{ width: 83 }} />
        </View>
        <View style={styles.mainSection}>
          <View style={styles.section}>
            <Text
              style={{
                marginTop: 36,
                marginBottom: 38,
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "center",
                color: "#18202f"
              }}
            >
              ATTESTATION DE FORMATION
            </Text>
            <View />
            <Text style={{ marginBottom: 32 }}>
              Je soussign&eacute;, Mathieu Brugière, Directeur Adjoint du
              département Formation de l&rsquo;organisme Revue Fiduciaire
              d&eacute;clar&eacute; en tant qu&rsquo;organisme formateur sous le
              n&deg; 11.75.32.96.075
            </Text>
            <Text style={{ marginBottom: 10 }}>
              certifie par la pr&eacute;sente que le participant :
            </Text>
            <Text style={{ marginBottom: 10 }}>
              Nom et pr&eacute;nom du participant : {fullName}
            </Text>
            <Text style={{ marginBottom: 10 }}>
              a bien suivi la formation &agrave; distance intitul&eacute;e :
            </Text>
            <Text style={{ marginBottom: 10 }}>RF e-Learning</Text>
            <Text style={{ marginBottom: 34 }}>
              du {formattedDate(startDate)} au {formattedDate(endDate)}
            </Text>
            <Text style={{ marginBottom: 10 }}>
              Soit un nombre total d&rsquo;heures de temps de formation
              r&eacute;alis&eacute;s de{" "}
              {getSecondsFormattedOnlyHours(
                timesPerDomain.reduce((sum, value) => sum + value.duration, 0)
              )}{" "}
              réparties par domaine de la manière suivante
            </Text>
            {timesPerDomain.map(timeDomain => (
              <Text key={timeDomain.domain.learningId}>
                - {timeDomain.domain.title} :{" "}
                {getSecondsFormattedOnlyHours(timeDomain.duration ?? 0)}
              </Text>
            ))}
            <Text style={{ marginTop: 34, marginBottom: 34 }}>
              Attestation t&eacute;l&eacute;charg&eacute;e le{" "}
              {formattedDate(new Date())}
            </Text>
          </View>
          <View
            style={[
              styles.halfSection,
              { border: "1pt solid #555555", paddingTop: 3, paddingBottom: 7 }
            ]}
          >
            <Text style={{ width: "100%", fontSize: 9 }}>
              Cachet obligatoire de l&rsquo;organisme de formation
            </Text>
            <Image
              src={tampon}
              style={{ width: 90, marginLeft: 15, marginTop: 15 }}
            />
          </View>
          <View
            style={[
              styles.halfSection,
              {
                borderTop: "1pt solid #555555",
                borderRight: "1pt solid #555555",
                borderBottom: "1pt solid #555555",
                paddingTop: 3,
                paddingBottom: 7
              }
            ]}
          >
            <Text style={{ width: "100%", fontSize: 9 }}>
              Signature du responsable de l&rsquo;organisme de formation
            </Text>
            <Image
              src={signature}
              style={{ width: 90, marginLeft: 15, marginTop: 15 }}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Certificate;
