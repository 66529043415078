// React
import React, { useState, useEffect, CSSProperties } from "react";
import { NavLink, NavLinkProps, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

// Material
import {
  Hidden,
  List,
  ListItemText,
  ListItem,
  ListSubheader,
  Collapse,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";

// Icons
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

// Style
import { makeStyles } from "@material-ui/core/styles";

// Interface
import { Domain, DOMAINS } from "../../models/domain.model";
import { User } from "../../models/user.model";

// Images
import logoRF from "../../assets/Logo_RF_Quadri.png";
import iconArtist from "../../assets/icons-icon-artists.svg";
import iconPerson from "../../assets/icons-icon-person.svg";

// Services
import { disconnectUser } from "../../services/user.service";

// Helpers
import { titleCase } from "../../helpers/card.helper";
import { getDomainColor, isMultipleDomains } from "../../helpers/domain.helper";

// Store
import { useSelector } from "react-redux";
import { StoreState } from "../../store";

// keycloak
import { useAuthentication } from "@dsk-lib/user";

// Config
import { CONST } from "../../config/constant";
import Scrollbar from "react-scrollbars-custom";

/**
 * Use style
 */
const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  containerClose: {
    height: "100%",
  },
  listContainer: {
    maxWidth: 360,
  },
  colorALaUne: {
    backgroundColor: "#CDE3DC",
  },
  textALaUne: {
    color: "#037450",
  },
  textSubheader: {
    fontWeight: 600,
    color: "#1d1d1b",
  },
  itemMenu: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  nav: {
    paddingTop: 0,
  },
  bullet: {
    width: 20,
    height: 10,
    borderRadius: 5,
    backgroundColor: "grey",
  },
  bulletSmall: {
    width: 10,
    height: 5,
    borderRadius: 5,
    backgroundColor: "grey",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  image: {
    width: 130,
  },
  logo: {
    width: 150,
  },
  smallTextItem: {
    fontSize: 14,
  },
  textDeconnection: {
    color: "#e51049",
  },
  adminLabel: {
    paddingLeft: 15,
  },
}));

/**
 * Bullet
 */
const Bullet = ({
  domain,
  isDomain = false,
}: {
  domain: Domain;
  isDomain?: boolean;
}) => {
  const classes = useStyles();

  return (
    <div
      className={isDomain ? classes.bullet : classes.bulletSmall}
      style={{ backgroundColor: getDomainColor(domain) }}
    />
  );
};

/**
 * Menu Props
 */
interface MenuProps {
  username?: string;
  isMobile?: boolean;
  onClose: () => void;
}
/**
 * Menu Component
 */
const Menu = (props: RouteComponentProps & MenuProps) => {
  const {
    location: { pathname },
    isMobile,
    username,
    onClose,
  } = props;

  /** Keycloak */
  const { keycloak } = useAuthentication();

  /** Classes */
  const classes = useStyles();
  /** Wrapped nav link */
  // tslint:disable-next-line:no-shadowed-variable
  const WrappedNavLink = React.forwardRef((props: NavLinkProps, ref: any) => (
    <NavLink {...props} innerRef={ref} />
  ));
  /** State Domain selected */
  const [domainSelected, setDomainSelected] = useState<string>();
  /** User state */
  const user: User | null = useSelector((state: StoreState) => state.user);
  /** Actuality selected */
  const actualitySelected = () => pathname.includes("actualites");
  /** Competence selected */
  const competenceSelected = () => pathname.includes("competences");

  /** Get background color */
  const getBackgroundColor = (
    domain: Domain,
    conditional = false
  ): CSSProperties => {
    return conditional ? { backgroundColor: getDomainColor(domain, 0.2) } : {};
  };
  /**
   * HandleCloseItem
   */
  const handleCloseItem = (slug?: string) => {
    if (isMobile) {
      if (slug) {
        setDomainSelected(slug);
      }
      onClose();
    }
  };

  /**
   * Use effect
   */
  useEffect(() => {
    const match = pathname.match(/^\/([-a-z0-9]+)+\//);
    setDomainSelected(match && match.length >= 2 ? match[1] : "");
  }, [pathname]);

  /**
   * Show "actualites" only if full domain access
   */
  const showActualityItem = (domainId: number): boolean =>
    !!user?.subscription.fullDomainsAccessEl.includes(domainId);

  return (
    <React.Fragment>
      <div className={classes.toolbar} />
      <Scrollbar>
        <List component="nav" className={classes.nav}>
          {user && !!user.subscription.domainsAccessEl?.length && (
            <React.Fragment>
              {user && user.subscription.fullDomainsAccessEl.length > 1 ? (
                <ListItem
                  component={WrappedNavLink}
                  to="/a-la-une"
                  button={true}
                  className={classes.colorALaUne}
                  onClick={() => handleCloseItem()}
                >
                  <ListItemIcon>
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    inset={false}
                    primary={"À la une"}
                    className={classes.textALaUne}
                  />
                </ListItem>
              ) : null}

              <ListSubheader
                component="div"
                className={classes.textSubheader}
                disableSticky={true}
              >
                {isMultipleDomains(
                  !!user?.subscription?.domainsAccessEl?.length
                )}
              </ListSubheader>
              {DOMAINS.filter((d) =>
                user?.subscription?.domainsAccessEl?.includes(d.learningId)
              ).map((domain: Domain) => (
                <React.Fragment key={domain.learningId}>
                  <ListItem
                    component={WrappedNavLink}
                    button={true}
                    onClick={() => handleCloseItem(domain.slug)}
                    to={`/${domain.slug}/${
                      showActualityItem(domain.learningId)
                        ? `actualites`
                        : `competences`
                    }`}
                    style={getBackgroundColor(
                      domain,
                      domainSelected === domain.slug
                    )}
                    className={classes.itemMenu}
                  >
                    <ListItemIcon>
                      <Bullet domain={domain} isDomain={true} />
                    </ListItemIcon>
                    <ListItemText primary={domain.title} />
                  </ListItem>
                  <Collapse
                    in={domainSelected === domain.slug}
                    timeout="auto"
                    unmountOnExit={true}
                  >
                    <List component="div" disablePadding={true}>
                      {showActualityItem(domain.learningId) ? (
                        <ListItem
                          component={WrappedNavLink}
                          button={true}
                          dense={true}
                          className={classes.nested}
                          to={`/${domain.slug}/actualites`}
                          selected={actualitySelected()}
                          style={getBackgroundColor(
                            domain,
                            actualitySelected()
                          )}
                          onClick={() => handleCloseItem()}
                        >
                          <ListItemIcon>
                            <Bullet domain={domain} />
                          </ListItemIcon>
                          <ListItemText primary="Actualités" />
                        </ListItem>
                      ) : null}
                      <ListItem
                        component={WrappedNavLink}
                        button={true}
                        dense={true}
                        className={classes.nested}
                        to={`/${domain.slug}/competences`}
                        selected={competenceSelected()}
                        style={getBackgroundColor(domain, competenceSelected())}
                        onClick={() => handleCloseItem()}
                      >
                        <ListItemIcon>
                          <Bullet domain={domain} />
                        </ListItemIcon>
                        <ListItemText primary="Socle de compétences" />
                      </ListItem>
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </React.Fragment>
          )}

          <ListSubheader
            component="div"
            className={classes.textSubheader}
            disableSticky={true}
          >
            MON TABLEAU DE BORD
          </ListSubheader>
          <Hidden smUp={true}>
            {username ? (
              <ListItem
                button={false}
                onClick={() => handleCloseItem()}
                className={classes.itemMenu}
              >
                <ListItemText primary={titleCase(username!)} />
              </ListItem>
            ) : null}
            {user?.isSuperAdmin && (
              <Typography className={classes.adminLabel} variant={"caption"}>
                Super Admin
              </Typography>
            )}
          </Hidden>
          {user?.subscription.pastDomainAccessEl?.length ? (
            <ListItem
              component={WrappedNavLink}
              to={"/mon-suivi"}
              button={true}
              onClick={() => handleCloseItem()}
              className={classes.itemMenu}
            >
              <ListItemIcon>
                <img src={iconPerson} alt="icon-artics" />
              </ListItemIcon>
              <ListItemText inset={false} primary={"Mon suivi"} />
            </ListItem>
          ) : null}
          {user?.isAdmin ? (
            <ListItem
              component={WrappedNavLink}
              to={"/admin"}
              button={true}
              onClick={() => handleCloseItem()}
              className={classes.itemMenu}
            >
              <ListItemIcon>
                <img src={iconArtist} alt="icon-artics" />
              </ListItemIcon>
              <ListItemText inset={false} primary={"Mes collaborateurs"} />
            </ListItem>
          ) : null}
          <Hidden smUp={true}>
            <Divider component="li" />
            <ListItem button={true} onClick={() => handleCloseItem()}>
              <ListItemText
                disableTypography={true}
                inset={true}
                primary={"Déconnexion"}
                onClick={() => disconnectUser(keycloak)}
                className={`${classes.smallTextItem} ${classes.textDeconnection}`}
              />
            </ListItem>
            <Divider component="li" />
          </Hidden>
          <ListSubheader
            component="div"
            className={classes.textSubheader}
            disableSticky={true}
          >
            EN SAVOIR PLUS
          </ListSubheader>
          <ListItem
            component={WrappedNavLink}
            to="/contact"
            button={true}
            onClick={() => handleCloseItem()}
            className={classes.itemMenu}
          >
            <ListItemText
              disableTypography={true}
              inset={true}
              primary={"Contactez-nous"}
              className={classes.smallTextItem}
            />
          </ListItem>
          <ListItem
            component={WrappedNavLink}
            to="/mention"
            button={true}
            onClick={() => handleCloseItem()}
            className={classes.itemMenu}
          >
            <ListItemText
              disableTypography={true}
              inset={true}
              primary={"Mentions légales"}
              className={classes.smallTextItem}
            />
          </ListItem>
        </List>
        <Grid
          container={true}
          alignItems="center"
          justifyContent="space-between"
          direction="column"
        >
          <Grid item={true}>
            <img
              src={logoRF}
              alt="revue fiduciaire"
              className={classes.image}
            />
          </Grid>
          <Grid item={true}>
            <Typography variant="body2" align={"center"}>
              Tous droits réservés
            </Typography>
            <Typography variant="body2" align={"center"}>
              Revue Fiduciaire © {CONST.CURRENT_YEAR}
            </Typography>
          </Grid>
        </Grid>
      </Scrollbar>
    </React.Fragment>
  );
};

export default withRouter(Menu);
